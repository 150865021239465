<template>
  <div id="app" style="height:100%">
    <!-- <van-nav-bar
      :title="$route.meta.name"
      
      @click-left="onClickLeft"
    />-->
    <!-- :left-text="$route.name=='home'?'':'返回'"
    :left-arrow="$route.name=='home'?false:true"-->
    <router-view :style="{marginBottom:$route.meta.no_tabbar == false || !$route.meta.no_tabbar?'80px':'0px'}" />

    <van-tabbar route v-if="$route.meta.no_tabbar == false || !$route.meta.no_tabbar">
      <van-tabbar-item icon="home-o" to="/">首页</van-tabbar-item>
      <van-tabbar-item icon="newspaper-o" to="/article">资讯</van-tabbar-item>
      <van-tabbar-item icon="tv-o" to="/meeting">会议室</van-tabbar-item>
      <!-- <van-tabbar-item icon="apps-o">工具</van-tabbar-item> -->
      <van-tabbar-item icon="contact" to="/my">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0
    };
  },
  watch: {
    $route(to, from) {
      if (this.$route.meta.no_auth == false || !this.$route.meta.no_auth) {
        let user = JSON.parse(localStorage.getItem("user"));

        if (!user) {
          this.$router.push("/login");
        }
      }
    }
  },
  components: {
    // HelloWorld
  },
  created() {
    // console.log(this.$router);
  },
  methods: {
    onClickLeft() {
      console.log(1);
    }
  }
};
</script>