<template>
  <div>
    <van-nav-bar :title="$route.meta.name" style="position: fixed; top: 0; right: 0; left: 0;">
      <template slot="title">
        <!-- <van-icon name="wap-home-o" /> -->
        {{$route.meta.name}}
      </template>
    </van-nav-bar>
    <div style="margin-top:4rem"></div>
    <!-- <van-swipe :autoplay="3000" indicator-color="white">
      <van-swipe-item>
        <img style="height:200px;width:100%" src="../assets/banner1.jpg" />
      </van-swipe-item>
      <van-swipe-item>
        <img style="height: 200px;width:100%" src="../assets/banner2.jpg" />
      </van-swipe-item>
    </van-swipe>-->
    <div style="    margin: 15px;">
      <img style="height:200px;width:100%;    border-radius: 15px;" src="../assets/banner2.jpg" />
    </div>
    <!-- <van-notice-bar color="#1989fa" background="#ecf9ff" text="通知内容" left-icon="volume-o" /> -->
    <!-- <van-divider content-position="center"></van-divider> -->
    <van-grid clickable :column-num="2">
      <van-grid-item icon="tv-o" text="进入会议室" url="https://meeting-room.zhoupengyu.cn" />
      <van-grid-item icon="search" text="翻译工具" to="/translate" />
    </van-grid>
    <section class="demo-skeleton">
      <van-divider content-position="center">优质翻译</van-divider>
      <!-- <van-skeleton
        title
        avatar
        :row="3"
        :loading="loading"
        v-for="(item,key) in translateData.data"
        :key="key"
      >
        <div>实际内容</div>
      </van-skeleton>-->
      <van-card
        :title="item.name"
        :thumb="item.avatar?item.avatar:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'"
        v-for="(item,key) in translateData.data"
        :key="key"
      >
        <div slot="tags" style="margin-top:5px;margin-bottom:10px;">
          <div class="bottom clearfix">
            <span class="time">
              学校：
              <font style="color:#29d">{{item.school}}</font>
            </span>

            <span class="time">
              学历：
              <font style="color:#29d">{{item.qual}}</font>
            </span>
          </div>
          <div class="bottom clearfix">
            <span class="time" style="width:50%">
              年龄：
              <font style="color:#29d">{{item.age}}</font>
            </span>

            <span class="time" style="width:50%">
              从业时间：
              <font style="color:#29d">{{item.work_time}}</font>
            </span>
          </div>

          <div class="bottom clearfix">
            <div class="bt-text">
              <i class="el-icon-location-information"></i>
              地区：{{item.address}}
            </div>
            <div class="bt-text">
              <i class="el-icon-mobile-phone"></i>
              电话：{{item.tel}}
            </div>
            <div class="bt-text">
              <i class="el-icon-paperclip"></i>
              邮箱：
              {{item.email}}
            </div>
            <div class="bt-text">
              <i class="el-icon-paperclip"></i>
              微信：
              {{item.wechat}}
              <van-button
                plain
                type="info"
                size="mini"
                v-clipboard:copy="item.wechat"
                v-clipboard:success="onCopy"
              >复制</van-button>
            </div>
          </div>
        </div>
        <div slot="footer">
          <van-button size="small" type="info" @click="showQrcode(item.wechat_img)">微信二维码</van-button>
          <van-button size="small" type="info" @click="toTalk(item.qq)">在线交谈</van-button>
        </div>
      </van-card>
      <!-- <van-skeleton
      title
      avatar
      :row="3"
      :loading="translateLoading"
      v-for="(item,key) in translateData.data"
      :key="key"
      class='demo-skeleton'
    >
      <div class="demo-preview">
        <img :src="item.avatar?item.avatar:'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'" />
        <div class="demo-content">
          <h3>关于 Vant</h3>
          <p>Vant 是一套轻量、可靠的移动端 Vue 组件库，提供了丰富的基础组件和业务组件，帮助开发者快速搭建移动应用。</p>
        </div>
      </div>
      </van-skeleton>-->
    </section>
  </div>
</template>

<script>
import { Notify,ImagePreview  } from "vant";
export default {
  name: "home",
  data() {
    return {
      opportunityData: {},
      translateData: {},
      listLoading: true,
      translateLoading: true
    };
  },
  created() {
    this.fetchTranslateData();
  },
  methods: {
    showQrcode(url){
      ImagePreview([url]);
    },
    onCopy(e) {
      Notify({ type: "success", message: "复制成功" });
    },
    goRoom() {
      window.open("https://meeting-room.zhoupengyu.cn");
    },
    rowLink(row, column, event) {
      console.log(row);
      this.$router.push("/article/content/?id=" + row.id);
    },
    toTalk(qq) {
      window.open(
        "mqqwpa://im/chat?chat_type=wpa&uin=" +
          qq +
          "&version=1&src_type=web&web_src=oicqzone.com"
      );
    },

    fetchOpportunityData(page) {
      // console.log(page)
      this.listLoading = true;
      let postData = this.$qs.stringify({
        cid: 1
      });
      this.$axios
        .post(this.$baseUrl + "/api/article/list", postData)
        .then(response => {
          this.opportunityData = response.data.data;
        });
    },
    fetchTranslateData(page) {
      // console.log(page)
      this.listLoading = true;
      let postData = this.$qs.stringify({
        page: page,
        pageSize: 9999,
        ...this.form
      });
      this.$axios
        .post(this.$baseUrl + "/api/fanyi/list", postData)
        .then(response => {
          this.translateData = response.data.data;
          this.translateLoading = false;
        });
    }
  }
};
</script>
<style scoped>
.van-nav-bar__title {
  max-width: 50% !important;
  margin: 0 !important;
}
.demo-skeleton {
  background-color: #fff;
}
.demo-skeleton .van-switch {
  margin: 0 16px 8px;
}
.demo-skeleton .demo-preview {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  padding: 0 16px;
  margin-top: 10px;
}
.demo-skeleton .demo-preview .demo-content {
  padding-top: 6px;
  text-align: left;
}
.demo-skeleton .demo-preview .demo-content h3 {
  margin: 0;
  font-size: 18px;
  line-height: 20px;
  text-align: left;
}
.demo-skeleton .demo-preview .demo-content p {
  margin: 13px 0 0;
  font-size: 14px;
  line-height: 20px;
}
.demo-skeleton .demo-preview img {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  margin-right: 16px;
}
</style>