import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import qs from 'qs'
import VueClipboard from "vue-clipboard2";
Vue.use(VueClipboard)
Vue.use(Vant);
Vue.prototype.$axios = axios;
Vue.prototype.$qs = qs;
Vue.config.productionTip = false
axios.defaults.withCredentials=true
if (process.env.NODE_ENV === 'production') {
  // mockXHR()
	Vue.prototype.$baseUrl = 'https://meeting-api.zhoupengyu.cn/';
}else{
  // Vue.prototype.$baseUrl = 'http://meeting-api.zhoupengyu.cn/';
  Vue.prototype.$baseUrl = 'http://meeting.localhost.com/';
  // Vue.prototype.$baseUrl = 'http://api.meeting.com/';

}
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
