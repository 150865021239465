import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta:{
      name:'中俄移动智能谈判室'
    }
  },
  {
    path: '/article',
    name: '/article',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Article.vue'),
    meta:{
      name:'资讯'
    }
  },
  {
    path: '/meeting',
    name: '/meeting',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Meeting.vue'),
    meta:{
      name:'会议室'
    }
  },
  {
    path: '/my',
    name: '/my',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this routMe
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/My.vue'),
    meta:{
      name:'我的'
    }
  },
  {
    path: '/articleDetail',
    name: '/articleDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this routMe
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ArticleDetail.vue'),
    meta:{
      name:'文章详情',
      no_tabbar:true
    }
  },
  {
    path: '/translate',
    name: '/translate',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this routMe
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Translate.vue'),
    meta:{
      name:'翻译',
      no_tabbar:true

    }
  },
  {
    path: '/login',
    name: '/login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this routMe
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta:{
      name:'登录',
      no_auth:true,
      no_tabbar:true
    }
  }
  
]

const router = new VueRouter({
  routes
})

export default router
